.card {
  width: 100%;
  height: 454px;
  background: #07182e;
  position: relative;
  display: flex;
  place-content: center;
  place-items: center;
  overflow: hidden;
  border-radius: 20px;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain image aspect ratio while covering the container */
  border-radius: 20px;
}

.card::before {
  content: '';
  position: absolute;
  width: 0.4px;
  background-image: linear-gradient(
    180deg,
    rgb(0, 183, 255),
    rgb(255, 48, 255)
  );
  height: 130%;
  animation: rotBGimg 3s linear infinite;
  transition: all 0.2s linear;
}

@keyframes rotBGimg {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.card::after {
  content: '';
  position: absolute;
  /* background: #07182e; */
  inset: 5px;
  border-radius: 15px;
}
